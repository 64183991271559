<template>
    <v-sheet class="text-center mx-auto pb-0 py-2" color="rgba(0,0,0,0)">
        <v-icon x-large>mdi-arrow-down-bold</v-icon>
    </v-sheet>
</template>

<script>
export default {
    props: ["color", "depth"]
}
</script>
